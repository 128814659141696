.leaflet-container {
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 48rem) {
  .leaflet-container {
    margin-bottom: 0rem;
  }
}

.leaflet-control-attribution.leaflet-control a,
.leaflet-control-attribution.leaflet-control span {
  display: none;
}

.marker-cluster-small,
.marker-cluster-small div {
  background-color: rgba(252, 122, 65, 0.2);
}

.marker-cluster-medium,
.marker-cluster-medium div {
  background-color: rgba(252, 122, 65, 0.5);
}

.marker-cluster-large,
.marker-cluster-large div {
  background-color: rgba(252, 122, 65, 0.8);
}

.marker-cluster div span {
  line-height: 30px;
  font-weight: 700;
  font-size: 1rem;
}

.loader {
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #222222,
      1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2),
      2.5em 0em 0 0em rgba(23, 23, 23, 0.2),
      1.75em 1.75em 0 0em rgba(23, 23, 23, 0.2),
      0em 2.5em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em 1.8em 0 0em rgba(23, 23, 23, 0.2),
      -2.6em 0em 0 0em rgba(23, 23, 23, 0.5),
      -1.8em -1.8em 0 0em rgba(23, 23, 23, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(23, 23, 23, 0.7),
      1.8em -1.8em 0 0em #222222, 2.5em 0em 0 0em rgba(23, 23, 23, 0.2),
      1.75em 1.75em 0 0em rgba(23, 23, 23, 0.2),
      0em 2.5em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em 1.8em 0 0em rgba(23, 23, 23, 0.2),
      -2.6em 0em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em -1.8em 0 0em rgba(23, 23, 23, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(23, 23, 23, 0.5),
      1.8em -1.8em 0 0em rgba(23, 23, 23, 0.7), 2.5em 0em 0 0em #222222,
      1.75em 1.75em 0 0em rgba(23, 23, 23, 0.2),
      0em 2.5em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em 1.8em 0 0em rgba(23, 23, 23, 0.2),
      -2.6em 0em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(23, 23, 23, 0.2),
      1.8em -1.8em 0 0em rgba(23, 23, 23, 0.5),
      2.5em 0em 0 0em rgba(23, 23, 23, 0.7), 1.75em 1.75em 0 0em #222222,
      0em 2.5em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em 1.8em 0 0em rgba(23, 23, 23, 0.2),
      -2.6em 0em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(23, 23, 23, 0.2),
      1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2),
      2.5em 0em 0 0em rgba(23, 23, 23, 0.5),
      1.75em 1.75em 0 0em rgba(23, 23, 23, 0.7), 0em 2.5em 0 0em #222222,
      -1.8em 1.8em 0 0em rgba(23, 23, 23, 0.2),
      -2.6em 0em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(23, 23, 23, 0.2),
      1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2),
      2.5em 0em 0 0em rgba(23, 23, 23, 0.2),
      1.75em 1.75em 0 0em rgba(23, 23, 23, 0.5),
      0em 2.5em 0 0em rgba(23, 23, 23, 0.7), -1.8em 1.8em 0 0em #222222,
      -2.6em 0em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(23, 23, 23, 0.2),
      1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2),
      2.5em 0em 0 0em rgba(23, 23, 23, 0.2),
      1.75em 1.75em 0 0em rgba(23, 23, 23, 0.2),
      0em 2.5em 0 0em rgba(23, 23, 23, 0.5),
      -1.8em 1.8em 0 0em rgba(23, 23, 23, 0.7), -2.6em 0em 0 0em #222222,
      -1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(23, 23, 23, 0.2),
      1.8em -1.8em 0 0em rgba(23, 23, 23, 0.2),
      2.5em 0em 0 0em rgba(23, 23, 23, 0.2),
      1.75em 1.75em 0 0em rgba(23, 23, 23, 0.2),
      0em 2.5em 0 0em rgba(23, 23, 23, 0.2),
      -1.8em 1.8em 0 0em rgba(23, 23, 23, 0.5),
      -2.6em 0em 0 0em rgba(23, 23, 23, 0.7), -1.8em -1.8em 0 0em #222222;
  }
}
